<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}
.btn1 {
	margin-bottom: 10px;
	width: 250px;
}

input[type='texte'] {
	width: 100%;
	padding: 12px 20px;
	margin: 4px 0;
	box-sizing: border-box;
	border: none;
	background-color: white;
	color: white;
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row v-if="Licenses">
			<b-col md="12">
				<h1 v-if="!$screen.sm" :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
					{{ this.caption1 }}
				</h1>
			</b-col>
			<b-col cols="12" xl="12">
				<transition name="slide">
					<!-- bg-variant="secondary" text-variant="white"  -->
					<!-- bg-secondary text-white -->
					<b-card no-body>
						<b-card-header v-if="$screen.sm">
							<h4>{{ this.caption1 }}</h4></b-card-header
						>
						<b-card-body v-if="newLic && newLic.user">
							<b-row>
								<!-- FIRST COLUMN WITH SEVERAL ROWS -->
								<b-col sm="6">
									<b-row>
										<b-col sm="12">
											<b-form-group :label="FormMSG(11, 'License:')" label-for="licName" :label-cols="3">
												<b-form-input v-model="newLic.license" type="text" id="licName"></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col sm="12">
											<b-form-group :label="FormMSG(12, 'Name:')" label-for="userName" :label-cols="3">
												<b-form-input v-model="newLic.user.name" type="text" id="userName"></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col sm="12">
											<b-form-group :label="FormMSG(13, 'First name:')" label-for="userFirstName" :label-cols="3">
												<b-form-input v-model="newLic.user.firstName" type="text" id="userFirstName"></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col sm="12">
											<b-form-group :label="this.FormMSG(80, 'Langue')" label-for="optionsForLanguages" :label-cols="3">
												<b-form-select
													v-model="newLic.userLanguage"
													:options="optionsForLanguages"
													id="optionsForLanguages"
												></b-form-select>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col sm="12">
											<b-form-group :label="FormMSG(14, 'Email:')" label-for="email" :label-cols="3">
												<b-form-input v-model="newLic.user.email" type="text" id="email"></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col sm="12">
											<b-form-group :label="FormMSG(15, 'Company:')" label-for="companyName" :label-cols="3">
												<b-form-input v-model="newLic.companyName" type="text" id="companyName"></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col sm="12">
											<SupplierSelector
												:title="`${!isSupplierUpdate ? FormMSG(250, 'Add supplier details') : FormMSG(251, 'Update supplier details')}`"
												:label="FormMSG(16, 'Company data')"
												:addlabel="FormMSG(30, 'Add')"
												:update-label="FormMSG(33, 'Update')"
												:placeholder="FormMSG(31, 'Filter by name...')"
												:supplier-id="companyId"
												type="licenseOlder"
												ref="refSupplier"
												@change="handleSupplierChange"
												@supplier:unselected="onSupplierUnselected"
											/>
										</b-col>
									</b-row>
									<b-row>
										<b-col sm="12">
											<b-form-group :label-cols="0">
												<b-form-textarea
													id="description"
													:placeholder="FormMSG(17, 'description...')"
													:no-resize="true"
													:state="newLic.description.length < 4000"
													v-model="newLic.description"
													rows="6"
												></b-form-textarea>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
								<!-- END FIRST COLUMN WITH SEVERAL ROWS -->
								<!-- START SECOND COLUMN WITH SEVERAL ROWS  representing the TO:...         -->
								<b-col sm="2"> </b-col>
								<b-col sm="3">
									<b-row>
										<b-form-group>
											<b-button class="btn1" @click="goBack()" variant="primary" size="lg"
												>{{ FormMSG(20, 'Back to licenses') }}&nbsp;<i class="icon-action-undo"></i
											></b-button>
										</b-form-group>
									</b-row>
									<b-row>
										<b-form-group>
											<b-button
												@click="curLicUpd()"
												v-html="FormMSG(21, 'Save modifications')"
												variant="primary"
												size="lg"
												class="btn1"
												:disabled="this.disableSave"
											>
											</b-button>
										</b-form-group>
									</b-row>
									<b-row>
										<b-form-group>
											<b-button
												class="btn1"
												@click="newProject()"
												v-html="FormMSG(32, 'New project')"
												variant="primary"
												size="lg"
												:disabled="this.curLic.id == 0"
											>
											</b-button>
										</b-form-group>
									</b-row>
								</b-col>
								<!-- END SECOND COLUMN WITH SEVERAL ROWS -->
							</b-row>
							<b-row>
								<b-col sm="6">
									<b-form-group>
										<H5> {{ this.createdByString }} </H5>
									</b-form-group>
								</b-col>
								<b-col sm="3"> </b-col>
							</b-row>
							<b-card no-body>
								<b-card-header>
									<h4>{{ this.caption4 }}</h4>
								</b-card-header>
								<b-card-body>
									<b-row>
										<b-col cols="12" xl="12">
											<!-- :head-variant="hv" -->
											<loading :active.sync="isLoading" :is-full-page="true"></loading>
											<CardListBuilder v-if="$screen.width < 992" :items="Projects" :fields="projectFields" @row-clicked="rowClicked">
												<template slot="actions" slot-scope="data">
													<b-button variant="primary" size="sm" @click="updateProject(data.item)" class="btn bg-transparent border-0">
														<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
													</b-button>
													<b-button variant="danger" size="sm" @click="delItem(data.item.id)" class="btn bg-transparent border-0">
														<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
													</b-button>
												</template>
											</CardListBuilder>
											<b-table
												v-if="$screen.width >= 992"
												:hover="hover"
												responsive="sm"
												ref="myTable"
												sticky-header
												selectable
												:selectedVariant="selectedColor"
												:select-mode="selectMode"
												:items="Projects"
												style="text-align: left"
												:fields="projectFields"
												:current-page="currentPage"
												:filter="filter"
												:per-page="perPage"
												bordered
												small
												:head-variant="hv"
											>
												<!-- <template slot="view" slot-scope="data"> -->
												<template v-slot:cell(view)="data">
													<b-button variant="primary" size="sm" @click="updateProject(data.item)" class="btn bg-transparent border-0">
														<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
													</b-button>
												</template>
												<template v-slot:cell(rem)="data">
													<b-button variant="danger" size="sm" @click="delItem(data.item.id)" class="btn bg-transparent border-0">
														<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
													</b-button>
												</template>
												<template v-slot:cell(copy)="data">
													<b-button variant="primary" size="sm" @click="copyItem(data.item.id)" class="btn bg-transparent border-0">
														<component :is="getLucideIcon(ICONS.COPY.name)" :color="ICONS.COPY.color" :size="20" />
													</b-button>
												</template>
											</b-table>
										</b-col>
									</b-row>
									<b-modal
										header-class="header-class-modal-doc-package"
										:title="FormMSG(60, 'DELETE ?')"
										class="modal-success"
										v-model="successModal"
										@ok="delItemConfirmed()"
										ok-variant="danger"
										:ok-title="this.FormMSG(66, 'Delete Project')"
									>
										{{ this.FormMSG(61, 'Are you sure you want to delete this item?') }}
									</b-modal>
									<b-modal
										header-class="header-class-modal-doc-package"
										:title="FormMSG(62, 'Copy Project?')"
										class="modal-success"
										v-model="successCopyModal"
										@ok="copyItemConfirmed()"
										ok-variant="primary"
										:ok-title="this.FormMSG(67, 'Copy Project')"
									>
										<input type="radio" id="allData" value="AllData" v-model="copyWhat" />
										<label for="allData">&nbsp;&nbsp;{{ this.FormMSG(63, 'Copy all data') }}</label>
										<br />
										<input type="radio" id="configData" value="AllConfig" v-model="copyWhat" />
										<label for="configData">&nbsp;&nbsp;{{ this.FormMSG(64, 'Copy configuration data only') }}</label>
									</b-modal>
								</b-card-body>
							</b-card>
						</b-card-body>
					</b-card>
				</transition>
			</b-col>
		</b-row>
	</errorContainer>
</template>

<script>
// import gql from 'graphql-tag';
import { store } from '@/store';
import Loading from 'vue-loading-overlay';
import { isNil, noTilde } from '@/shared/utils';
import SupplierSelector from '@/components/SupplierSelector';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getLicenseWithId, updLicense } from '@/cruds/license.crud';
import { getProjects, copyProject, delProject } from '@/cruds/project.crud';

export default {
	name: 'License',
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Loading,
		SupplierSelector
	},
	data: () => {
		return {
			hv: 'dark',
			erreur: {},
			formDataObj: {},
			Licenses: [],
			Projects: [],
			curLic: {},
			newLic: {},
			currentPage: 1,
			currentIdx: 0,
			stopPropagation: '',
			nativeEvent: '',
			successModal: false,
			successCopyModal: false,
			perPage: 0,
			warning: '',
			filter: '',
			projectIdToDelete: 0,
			projectIdToCopy: 0,
			isLoading: false,
			selectMode: 'single',
			selectedColor: 'primary',
			firstTime: true,
			createdByString: '',
			copyWhat: 'AllData',
			copyAllConfig: false,
			editData: {
				type: Object,
				required: false,
				default: null
			},
			isSupplierUpdate: false
		};
	},
	computed: {
		optionsForLanguages() {
			let menus = this.FormMenu(1);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return menus;
		},
		caption4() {
			return this.FormMSG(26, 'List of projects');
		},
		projectFields() {
			return [
				{
					key: 'id',
					label: this.FormMSG(100, 'id'),
					sortable: true
				},
				{
					key: 'titre',
					label: this.FormMSG(104, 'Title'),
					sortable: true
				},
				{
					key: 'production',
					label: this.FormMSG(101, 'Production company'),
					sortable: true
				},
				{
					key: 'realisateur',
					label: this.FormMSG(102, 'Director'),
					sortable: true
				},
				{
					key: 'view',
					label: this.FormMSG(103, 'Details'),
					sortable: false,
					class: 'text-center',
					formatter: (v) => v
				},
				{
					key: 'rem',
					label: this.FormMSG(7, 'Delete'),
					class: 'text-center',
					sortable: false,
					formatter: (v) => v
				},
				{
					key: 'copy',
					label: this.FormMSG(8, 'Copy'),
					sortable: false,
					class: 'text-center',
					formatter: (v) => v
				}
			];
		},
		showMessageBody() {
			//console.log("in showMessageBody ");
			return this.newLic.toEmail;
		},
		disableSave() {
			if (
				this.newLic.license != this.curLic.license ||
				this.newLic.companyName != this.curLic.companyName ||
				this.newLic.description != this.curLic.description ||
				this.newLic.user.email != this.curLic.user.email ||
				this.newLic.user.name != this.curLic.user.name ||
				this.newLic.user.firstName != this.curLic.user.firstName ||
				this.newLic.companyId != this.curLic.companyId
			) {
				return false;
			}
			return true;
		},
		caption1() {
			var newCaption = this.FormMSG(10, 'License:') + ' ' + this.newLic.license;
			return newCaption;
		},
		companyId() {
			// current supplier id of edit data
			this.isSupplierUpdate = true;

			if (isNil(this.newLic) || parseInt(this.newLic.companyId, 10) === 0) {
				this.isSupplierUpdate = false;

				return 0;
			}

			return this.newLic.companyId;
		}
	},
	methods: {
		copyItem(id) {
			this.projectIdToCopy = parseInt(id, 10);
			this.successCopyModal = true;
		},
		async copyItemConfirmed() {
			this.isLoading = true;
			var ProjectId = parseInt(this.projectIdToCopy, 10);
			var Filter = {};
			if (this.copyWhat == 'AllData') {
				Filter.allData = true;
				Filter.allConfig = true;
			} else {
				Filter.allData = false;
				Filter.allConfig = true;
			}

			try {
				await copyProject(ProjectId, Filter);
				this.isLoading = false;
				this.reloadProjectsData();
				window.location.reload(true);
			} catch (error) {
				this.isLoading = false;
				console.log(error);
				this.erreur = error;
			}
		},
		delItem(id) {
			this.projectIdToDelete = parseInt(id, 10);
			this.successModal = true;
		},
		async delItemConfirmed() {
			var ProjectId = parseInt(this.projectIdToDelete, 10);

			try {
				await delProject(ProjectId);
				this.reloadProjectsData();
			} catch (error) {
				console.log(error);
				this.erreur = error;
			}
		},
		async reloadProjectsData() {
			//fetchPolicy: 'no-cache'
			var licenseID = parseInt(this.newLic.id, 10);
			// console.log("in load projects, licenseID=",licenseID);
			try {
				const result = await getProjects(licenseID);
				this.Projects = result;
			} catch (error) {
				console.log('errors in getting projects:', error);
				this.erreur = error;
			}
		},
		handleSupplierChange(supplier) {
			this.isSupplierUpdate = false;
			//console.log("SupplierSelector:", supplier);
			if (isNil(supplier)) return;

			this.isSupplierUpdate = true;
			const { id, supplierName } = supplier;
			//console.log("SupplierSelector id:", id);
			this.newLic.companyId = parseInt(id);
			//this.newLic.companyName = supplierName;
			this.newLic.company.name = supplierName;
			this.newLic.company.id = this.newLic.companyId;
			//console.log("handleSupplierChange this.newLic:", this.newLic);
		},
		setCreatedByString() {
			var date =
				this.newLic.createdAt.split('T')[0].substring(8) +
				'/' +
				this.newLic.createdAt.split('T')[0].substring(5, 7) +
				'/' +
				this.newLic.createdAt.split('T')[0].substring(0, 4);
			date = date + ' ' + this.newLic.createdAt.substring(11, 13) + ':' + this.newLic.createdAt.substring(14, 16);
			return this.FormMSG(41, 'Created by:') + ' ' + this.newLic.creator.name + ' ' + this.newLic.creator.firstName + '    - ' + date;
		},
		viewProject(item) {},
		fillUpdatedLicense() {
			var UpdatedLicense = {
				...this.newLic
			};
			//console.log("UpdatedAccomodation:", UpdatedAccomodation)
			return UpdatedLicense;
		},
		async curLicUpd() {
			// loading variables from newLic
			//console.log("before call to update license, this.newLic:", this.newLic);
			var UpdatedLicense = this.fillUpdatedLicense();
			console.log('before call to update license,UpdatedLicense:', UpdatedLicense);

			var LicenseId = parseInt(this.newLic.id, 10);
			console.log('UpdatedLicense1:', UpdatedLicense);

			try {
				const UpdLicense = await updLicense(LicenseId, UpdatedLicense);

				// modify curLic from newLic
				this.curLic = Object.assign({}, UpdLicense);
				this.newLic = Object.assign({}, UpdLicense);
				this.newLic.user = Object.assign({}, this.curLic.user);
				// find object in the list and update accordingly
				//console.log("after acc update result:", result);
				//console.log("after acc update newLic:", this.newLic);
				for (var i = 0; i < this.Licenses.length; i++) {
					if (this.Licenses[i].id == UpdLicense.id) {
						this.Licenses[i].name = this.curLic.name;
						this.Licenses[i].fileName = this.curLic.fileName;
						break;
					}
				}
			} catch (error) {
				console.log(error);
				//this.erreur = error
				var myerrorObject = {
					err: ''
				};
				myerrorObject.err = error.message;
				this.erreur = myerrorObject;
			}
		},
		initValuesForNoRecords() {
			this.newLic.license = ' NEW License';
			this.newLic.id = 0;
			this.newLic.companyName = '';
			this.newLic.companyId = 0;
			this.newLic.userId = 0;
		},
		async reloadData() {
			var LicenseId;
			if (!isNil(store.state.curLicense)) {
				LicenseId = parseInt(store.state.curLicense.id, 10);
				// console.log("STORED IN STORE  licenseId:", LicenseId);
			} else {
				LicenseId = parseInt(this.$route.params.id, 10);
				// console.log("NOT STORED IN STORE licenseId:", LicenseId);
			}

			try {
				const GetLicenseWithId = await getLicenseWithId(LicenseId);

				this.Licenses = GetLicenseWithId;

				// select element
				if (this.Licenses.length > 0) {
					this.curLic = this.Licenses[0];
					this.newLic = Object.assign({}, this.curLic);
					this.newLic.user = Object.assign({}, this.curLic.user);
					var idx = store.getCurrentLicenseTableIndex();
					//console.log("var idx = store.state.getCurrentAccomodationTableIndex", idx);
					this.createdByString = this.setCreatedByString();
					this.firstTime = false;
				} else {
					console.log('in reload data initValuesForNoRecords');
					this.initValuesForNoRecords();
					console.log('end initValuesForNoRecords:', this.newLic);
				}

				//console.log("Licenses:", this.Licenses);
				this.$nextTick(function () {
					// updating supplier component
					var selsup = {
						id: this.curLic.companyId,
						name: this.curLic.company.name
					};
					//console.log("selsup:", this.$refs.refSupplier);
					this.$refs.refSupplier.setSelectedSupplier(selsup);
					// end updating supplier component
				});
				this.reloadProjectsData();
			} catch (error) {
				console.log('in reload data:', error);
				this.erreur = error;
			}
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		rowClicked(item, index) {},
		newProjectDetailLink(id) {
			var str = '/licenseList/' + id.toString() + '/newProject';
			// console.log('project form newProjectDetailLink str:', str);
			return str;
		},
		newProject() {
			this.$router.push(this.newProjectDetailLink(this.newLic.id));
		},
		updateProjectDetailLink(LicenseId, ProjectId) {
			var str = '/licenseList/' + LicenseId.toString() + '/updProject/' + ProjectId.toString();
			// console.log('project form updateProjectDetailLink str:', str);
			return str;
		},
		updateProject(item) {
			this.$router.push(this.updateProjectDetailLink(this.newLic.id, item.id));
		},
		goBack() {
			this.$router.go(-1);
		},
		onSupplierUnselected() {
			this.isSupplierUpdate = false;
		}
	},
	created() {
		this.reloadData();

		const isNewProjectCreated = localStorage.getItem('license:project-created');
		if (isNewProjectCreated) {
			this.createToastForMobile(this.FormMSG(252, 'Information'), this.FormMSG(1300, 'New project created successfully'), '', 'success');
			localStorage.removeItem('license:project-created');
		}
	}
};
</script>
